import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/Tooltip";
import { Skeleton } from "@/components/ui/skeleton";

type GalleryMessage = {
  id: string;
  created_at: string;
  prompt: string;
  chat_id: string;
  screenshot: string; // presigned URL
  // username: string; // username not used because it needs a profile table in Supabase to be set up first
};

type GalleryProps = {
  messages: GalleryMessage[];
};

function Gallery({ messages }: GalleryProps) {
  return (
    <>
      {messages.map((message, index) => (
        <div className="w-full max-w-xs text-center" key={index}>
          <a
            href={`/render/${message.chat_id}/${message.id}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <img
              className="border border-1 hover:shadow-lg transition-all object-cover object-center w-full h-48 mx-auto rounded-lg"
              alt="preview"
              src={message.screenshot}
            />
          </a>

          <TooltipProvider delayDuration={400}>
            <Tooltip>
              <TooltipTrigger className="cursor-default">
                <p
                  className="mt-2 rounded-lg px-4 py-2 bg-gray-100 text-left"
                  style={{
                    width: "20rem",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {/* <a className="font-medium text-black"> */}
                  {/*   {message.username}: &nbsp; */}
                  {/* </a> */}
                  <span className="text-gray-700 truncate truncate-ellipsis">
                    {message.prompt}
                  </span>
                </p>
              </TooltipTrigger>
              <TooltipContent className="bg-white">
                <p>{message.prompt}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      ))}
    </>
  );
}

type GallerySkeletonProps = {
  limit: number;
};

function GallerySkeleton({ limit }: GallerySkeletonProps) {
  return (
    <>
      {[...Array(limit)].map((_, index) => (
        <div className="mx-auto flex flex-col space-y-3" key={index}>
          <Skeleton className="h-[190px] w-[318px] bg-gray-100 rounded-xl" />
          <div className="space-y-2">
            <Skeleton className="h-[32px] w-[320px] bg-gray-100 rounded-md" />
          </div>
        </div>
      ))}
    </>
  );
}

export { Gallery, GallerySkeleton };
