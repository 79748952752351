import { Gallery, GallerySkeleton } from "@/components/ui/Gallery";
import axios from "axios";
import { useState } from "react";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

type ShowcaseProps = {
  limit: number;
};

function Showcase({ limit = 12 }: ShowcaseProps) {
  const [messages, setMessages] = useState<Array<any>>([]);

  function getShowcaseMessages() {
    if (messages.length > 0) {
      return;
    }

    const url = `${BACKEND_URL}/api/public/chats/shared/messages?limit=${limit}`;
    const headers = {
      "Content-Type": "application/json",
    };

    try {
      axios
        .get(url, { headers })
        .then((response) => {
          setMessages(response.data);
          console.log(`Messages: ${JSON.stringify(response.data)}`);
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  }

  const [initialized, setInitialized] = useState<boolean>(false);

  if (!initialized) {
    getShowcaseMessages();
    setInitialized(true);
  }

  return (
    <>
      <section className="px-2 mt-10 lg:mt-32 mb-5 lg:mb-16 bg-white md:px-0">
        <div className="container px-6 mx-auto">
          <h2 className="text-2xl font-semibold text-center text-gray-800 capitalize lg:text-3xl">
            Featured
          </h2>

          <div className="grid gap-8 mt-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {messages.length > 0 ? (
              <Gallery messages={messages} />
            ) : (
              <GallerySkeleton limit={limit} />
            )}
          </div>
        </div>
      </section>
    </>
  );
}

export default Showcase;
