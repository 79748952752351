import { Routes, Route, Outlet, Link, Navigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { SessionContext, SessionProvider } from "@/contexts/SessionContext";
import { useNavigate } from "react-router-dom";
import { Theme } from "@radix-ui/themes";
import { FrigadeForm, FrigadeProvider, FrigadeTour } from "@frigade/react";
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import Signup from "@/pages/Signup";
import supabase from "@/clients/supabase";
import DashboardHome from "@/pages/dashboard/Home";
import NewChat from "@/pages/dashboard/NewChat";
import PricingPage from "@/pages/payments/PricingPage";
import Footer from "@/components/ui/Footer";
import DashboardRender from "@/pages/dashboard/Render";
import Showcase from "@/pages/Showcase";
import Render from "@/pages/Render";

const FRIGADE_PUBLIC_API_KEY = import.meta.env.VITE_FRIGADE_PUBLIC_API_KEY;
const FRIGADE_FLOW_ID = import.meta.env.VITE_FRIGADE_FLOW_ID;
const FRIGADE_FORM_FLOW_ID = import.meta.env.VITE_FRIGADE_FORM_FLOW_ID;

export default function App() {
  return (
    <SessionProvider>
      <Theme>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="showcase" element={<Showcase limit={12} />} />
            <Route path="pricing" element={<PricingPage />} />
          </Route>

          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />

          <Route path="render" element={<SemiPublicLayout />}>
            <Route path=":chatId/:messageId" element={<Render />} />
          </Route>

          <Route path="dashboard" element={<SecuredLayout />}>
            <Route index element={<NewChat />} />
            <Route path="chats/:chatId" element={<DashboardHome />} />
            <Route path="chats/:chatId/preview" element={<DashboardRender />} />
            <Route
              path="chats"
              element={<Navigate to="/dashboard" replace />}
            />
          </Route>
        </Routes>
      </Theme>
    </SessionProvider>
  );
}

function Layout() {
  const sessionContext = useContext(SessionContext);
  const { session } = sessionContext;

  return (
    <div className="mb-5">
      <div className="flex flex-row">
        <section className="relative w-full text-gray-800 bg-white body-font">
          <div className="container flex flex-col flex-wrap items-center justify-between py-5 mx-auto md:flex-row md:px-5 max-w-7xl">
            <Link
              className="relative z-10 flex items-center w-auto text-2xl font-extrabold leading-none text-black select-none"
              to="/"
            >
              Makedraft
            </Link>

            <div className="relative z-10 inline-flex items-center space-x-4 mt-5 lg:mt-0 md:ml-5 lg:justify-end">
              <a className="mr-2" href="https://docs.makedraft.com/updates">
                <span>Updates</span>
              </a>
              <a className="mr-2" href="https://docs.makedraft.com">
                <span>About</span>
              </a>

              <Link className="mr-2" to="/pricing">
                Pricing
              </Link>

              {session ? (
                <Link
                  className="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-orange-500/80 whitespace-no-wrap ring-1 ring-orange-500/80 rounded-2xl shadow hover:bg-orange-400 hover:text-neutral-100 focus:outline-none focus:shadow-none"
                  to="/dashboard"
                >
                  Dashboard
                </Link>
              ) : (
                <>
                  <Link
                    className="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-neutral-100 whitespace-no-wrap bg-red-500/80 rounded-2xl shadow-sm hover:bg-red-500 focus:outline-none focus:shadow-none"
                    to="/signup"
                  >
                    Sign Up
                  </Link>

                  <Link
                    className="inline-flex items-center justify-center px-4 py-2 text-base font-medium text-orange-500/80 whitespace-no-wrap ring-1 ring-orange-500/80 rounded-2xl shadow hover:bg-orange-400 hover:text-neutral-100 focus:outline-none focus:shadow-none"
                    to="/login"
                  >
                    Login
                  </Link>
                </>
              )}
            </div>
          </div>
        </section>
      </div>
      <Outlet />
      <Footer />
    </div>
  );
}

function SemiPublicLayout() {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState<any>(true);

  // Supabase
  useEffect(() => {
    setIsLoading(true);
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setIsLoading(false);
    });
    return () => subscription.unsubscribe();
  }, []);

  if (isLoading) {
    return <div></div>;
  } else {
    return <Outlet />;
  }
}

function SecuredLayout() {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState<any>(true);
  const navigate = useNavigate();

  // Supabase
  useEffect(() => {
    setIsLoading(true);
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      setIsLoading(false);
    });
    return () => subscription.unsubscribe();
  }, []);

  if (isLoading) {
    return <div></div>;
  } else {
    if (!session) {
      navigate("/login");
    } else {
      // TODO: This needs to be refactored
      // Currently we declare 2 SandpackProviders
      // This is so we can access useActiveCode() in the DashboardHome route
      return (
        <>
          <FrigadeProvider
            config={{
              debug: true,
              defaultAppearance: {
                theme: {
                  colorBackground: "#F3F4F6",
                  colorBorder: "#C4C4C4",
                },
              },
            }}
            publicApiKey={FRIGADE_PUBLIC_API_KEY}
            userId={session.user.id}
          >
            <FrigadeForm
              flowId={FRIGADE_FORM_FLOW_ID}
              type="modal"
              dismissible={false}
            />

            <FrigadeTour
              flowId={FRIGADE_FLOW_ID}
              dismissible={true}
              appearance={{
                styleOverrides: {
                  tourHighlightContainer: {
                    zIndex: "200 !important",
                  },
                  tooltipContainerWrapper: {
                    zIndex: "200 !important",
                  },
                },
              }}
            />

            <Outlet />
          </FrigadeProvider>
        </>
      );
    }
  }
}
